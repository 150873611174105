<template>
  <div class="userLogin" :style="{ '--theme': 'var(--navBg)' }">
    <van-nav-bar style="background-color: var(--navBg);height: calc(50rem / 16);" :border="false">
      <template #right>
        <Lan />
      </template>
    </van-nav-bar>
    <img class="img_logo" :src="require(`@/assets/ico/${ver}_logo.png`)" alt="">
    <img class="logo" :src="require(`@/assets/ico/${ver}.png`)" />
    <div class="user">
      <div>
        <div class="login-title">
          <!-- <p class="versions">
            {{ $t('Login.versions') }}
          </p> -->
          <div>
            <!-- <span>{{ $t('Login.masuk') }}</span> -->
            <!-- <span>{{ "page.login" | lan }}</span> -->
            <!-- <p>{{ $t('Login.name') }}</p> -->
            <!-- <p>v1.0.7</p> -->
          </div>
        </div>
        <div class="cont">
          <div v-for="(item, index) in formList" :key="index" class="border-bottom-calss">
            <!-- <img :src="item.icon" /> -->
            <van-icon :name="item.icon" color="#fff" size="18" style="margin-right: calc(12rem / 16);" />
            <div :style="{
              'justify-content':
                item.key === 'userName' ? '' : 'space-between',
            }">
              <span v-if="item.key === 'userName' && country == 'tr'" style="
                  padding-left: 15px;
                  padding-right: 12px;
                  border-right: 1px solid #ccc;
                  border-left: 1px solid #ccc;
                  color: #fff;
                ">+90</span>
              <span v-if="item.key === 'userName' && verConfig.areaCode" style="
                  padding-left: 15px;
                  padding-right: 12px;
                  border-right: 1px solid #ccc;
                  border-left: 1px solid #ccc;
                  color: #fff;
                ">{{ verConfig.areaCode }}</span>
              <input :type="item.type" :placeholder="$t(item.placeholder)" :maxlength="item.length" v-model="form[item.key]"
                :adjust-position="true" @focus="handleOnfocus" @blur="handleOnblur" />
              <!-- <img v-if="item.key === 'password'" src="@/assets/Img/login/icon/DL_0002_biyan.png"
                @click="handleLookPass" /> -->
              <i v-if="item.key === 'password'" slot="right-icon" class="iconfont passwordIcon" :class="!passwordShow
                ? 'icon-yanjing_yincang_o'
                : 'icon-yanjing_xianshi_o'
                " @click="handleLookPass"></i>
            </div>
          </div>
          <van-checkbox v-model="checked" shape="round" class="checkbox">
            {{ $t("Login.remenber") }}
          </van-checkbox>
          <van-button size="normal" @click="handleLogin" color="var(--theme)">
            {{ $t("Login.loginButton") }}
          </van-button>
        </div>
        <div class="table">
          <div v-for="(item, index) in tablelist" :key="index" @click="handleTable(item.key)">
            <span>{{ $t(item.label) }}</span>
          </div>
        </div>
      </div>
      <!-- <div>
        <input type="text" v-model="message" />
        <button
          type="button"
          v-clipboard:copy="message"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          Copy!
        </button>
      </div> -->
      <!-- <img class="middle" src="@/assets/Img/login/img/DL_0009_yuan3.png" />
      <img
        class="bottom"
        src="@/assets/Img/login/img/DL_0010_yuan2.png"
        v-if="isBottomIconShow"
      />
      <img class="top" src="@/assets/Img/login/img/DL_0011_yuan1.png" /> -->
      <!-- <img class="logo" :src="require(`@/assets/ico/${ver}.png`)" /> -->

      <!-- <div class="img-cont" v-if="isBottomIconShow">
        <img class="top" src="@/assets/Img/login/img/DL_tok.png" />
        <img class="top" src="@/assets/Img/login/img/DL_Qoo.png" />
        <img class="top" src="@/assets/Img/login/img/DL_buk.png" />
      </div> -->
    </div>
    <LoadingRE :show="LoadingShow" />
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Toast,
  Checkbox,
  CheckboxGroup,
  Dialog,
} from "vant";

// 设置vant的多语言
import md5 from "js-md5";
import Cookies from "js-cookie";
import { Locale } from "vant";
import enUS from "vant/lib/locale/lang/en-US";
import Modelu from "@/utils/Module";
import { getNewPhone } from "@/utils/tools";
import Lan from '@/components/lan';

import { REQUEST_API, REQUEST_API2, REQUEST_API3 } from "@/http/api";
import { LOGIN } from "@/api";
import LoadingRE from "@/components/LoadingRE";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    LoadingRE,
    Lan
  },
  data() {
    return {
      LoadingShow: false,
      message: "copy",
      country: Cookies.get("language"),
      form: {
        userName: "",
        password: "",
      },
      formList: [
        {
          placeholder: "Login.nameInput",
          icon: 'contact',
          key: "userName",
          type: "text",
          length: 99,
        },
        {
          placeholder: "Login.pwdInput",
          icon: 'lock',
          key: "password",
          type: "password",
          length: 99,
        },
      ],
      tablelist: [
        // {
        //   label: `${this.$t('Login.forgot')}`,
        //   key: 'forgot',
        // },
        {
          label: "Login.registrasi",
          key: "registrasi",
        },
      ],
      isBottomIconShow: true,
      checked: false,
      thePwdObj: {
        userName: "",
        passwordL: "",
      },
      show: false,
      passwordShow: false
    };
  },
  watch: {},
  created() {
    this.init();
    this.isBottomIconShow = true;
    window.localStorage.removeItem("userInfo");
    window.localStorage.removeItem("token");
  },
  methods: {
    onCopy(e) {
      // console.log(e.text)
    },
    onError(e) {
      // console.log("失败");
    },
    handleOnfocus() {
      this.isBottomIconShow = false;
      // console.log('focus', this.isBottomIconShow)
    },
    handleOnblur() {
      if (!this.isBottomIconShow) {
        this.isBottomIconShow = true;
      } else {
        setTimeout(() => {
          this.isBottomIconShow = true;
        }, 200);
      }
    },
    onSelect(e) {
      let _this = this;
      _this.country = e.type;
      console.log("country: ", country);
      switch (e.type) {
        case "en":
          this.$i18n.locale = e.type;
          Locale.use("en-US", enUS);
          Cookies.set("language", e.type);
          break;
        case "idn":
          // window.console.log(e.type)
          this.$i18n.locale = e.type;
          Locale.use("en-US", enUS);
          Cookies.set("language", e.type);
          break;
      }
      this.show = false;
    },
    onClose() {
      this.show = false;
    },
    /** handle **/
    handleLookPass() {
      if (this.formList[1].type === "password") {
        this.formList[1].type = "text";
        this.passwordShow = true;
      } else {
        this.formList[1].type = "password";
        this.passwordShow= false;
      }
    },
    handleLogin() {
      let count = 0;
      for (let key in this.form) {
        if (this.form[key]) {
          count++;
        }
      }
      if (count > 1) {
        // Toast(this.$router.history.current.name)
        this.login();
      } else {
        Toast(this.$t("Hint.notInput")); // 输入内容不完整
      }
    },
    handleTable(key) {
      this.$router.push(key);
    },
    /** API **/
    login() {
      let form = {
        userphone: this.form.userName,
        userpass: this.form.password,
      };
      localStorage.setItem("the-pwd-state", this.checked);
      if (this.checked) {
        localStorage.setItem("the-pwd-obj", JSON.stringify(form));
      }
      this.$store.dispatch("login", { ...form });
      // this.LoadingShow = true
      // LOGIN(form) // http请求
      //   .then((r) => {
      //     this.$store.commit('SET_LOGIN_INFO', {
      //       phone: getNewPhone(this.form.userName),
      //       pwd: this.form.password,
      //       channel: Modelu.signStrFunc(),
      //     })
      //     if (r.data.ret === 1) {
      //       this.$cookie.set('userInfo', JSON.stringify(r.data.data))
      //       this.$cookie.set('token', r.data.data.token)
      //       this.$cookie.set('the-pwd-state', this.checked)
      //       if (this.checked) {
      //         this.$cookie.set(
      //           'the-pwd-obj',
      //           JSON.stringify({
      //             userName: this.form.userName,
      //             password: this.form.password,
      //           })
      //         )
      //       } else {
      //         this.$cookie.remove('the-pwd-obj')
      //       }
      //     } else {
      //       this.LoadingShow = false
      //       Toast(r.data.msg)
      //     }
      //     return r.data.ret
      //   })
      //   .then((ret) => {
      //     if (ret === 1) {
      //       this.$api.getUserInfo(
      //         (res) => {},
      //         (err) => {},
      //         () => {
      //           this.LoadingShow = false
      //           this.$router.replace({ name: 'goods' })
      //         },
      //         this.$cookie.get('token')
      //       )
      //     }
      //   })
      //   .catch((e) => {
      //     this.LoadingShow = false
      //     console.log(e)
      //   })
    },
    /** 其他 **/
    init() {
      let storePwdState = localStorage.getItem("the-pwd-state");
      let storePwd = localStorage.getItem("the-pwd-obj");
      let initObj = {
        userName: "",
        passwordL: "",
      };
      this.checked = storePwdState ? JSON.parse(storePwdState) : false;
      this.form = storePwd
        ? JSON.parse(storePwd)
          ? JSON.parse(storePwd)
          : initObj
        : initObj;
    },
  },
};
</script>

<style lang="less" scoped>
@checkbox-label-color: #88888d;

.userLogin {
  position: relative;
  width: 100%;
  height: calc(100vh - (110rem / 16));
  height: 100vh;
  background-color: #000;

  /deep/ .van-nav-bar__content {
    background-color: var(--navBg);
    height: calc(50rem / 16);
  }

  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }

  .passwordIcon {
    font-size: calc(26rem / 16);
    color: #fff;
  }
  .img_logo{
    display: block;
    height: calc(40rem /16);
    margin-top: calc(10rem / 16);
    margin-left: calc(16rem / 16);
  }
  .logo {
    display: block;
    min-width: calc(100rem / 16);
    height: calc(100rem / 16);
    margin: calc(60rem / 16) auto calc(20rem / 16);
  }

  .user {

    // margin-top: calc(110rem / 16);
    // padding-top: calc(110rem / 16);
    >div {
      width: 85%;
      margin: auto;
      color: #000;

      >.login-title {
        margin-bottom: calc(70rem / 16);

        >p {
          font-size: calc(12rem / 16);
          line-height: calc(12rem / 16);
          margin-bottom: calc(5rem / 16);
        }

        >div {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          >span {
            font-size: calc(24rem / 16);
            line-height: calc(24rem / 16);
            color: #fff;
            // border-right: 0.0625rem solid #cbcbcb;
            // padding-right: calc(5rem / 16);
            // margin-right: calc(5rem / 16);
          }

          >p {
            font-size: calc(12rem / 16);
            line-height: calc(18rem / 16);
          }
        }
      }

      >.cont {
        >.border-bottom-calss {
          border-bottom: 0.0625rem solid #cbcbcb;
        }

        >div {
          display: flex;
          align-items: center;
          padding: 0.625rem calc(10rem / 16);

          >img:nth-child(1) {
            width: calc(18rem / 16);
            height: calc(18rem / 16);
            margin-right: calc(8rem / 16);
          }

          >div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            >input {
              width: calc(220rem / 16);
              border: 0;
              // border-left: calc(1rem / 16) solid #cbcbcb;
              padding-left: calc(5rem / 16);
              font-size: calc(15rem / 16);
              color: #fff;
              background-color: transparent;
            }

            >img:nth-last-child(1) {
              width: calc(30rem / 16);
              margin-right: calc(15rem / 16);
            }
          }
        }

        >div:nth-child(1) {
          margin-bottom: calc(30rem / 16);
        }

        >button {
          width: 100%;
          // margin-top: calc(40rem / 16);
          color: #fff;
          font-size: calc(15rem / 16);
        }
      }

      >.table {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        margin-top: calc(34rem / 16);

        >div {
          width: 50%;
          display: flex;
          justify-content: center;
          font-size: calc(14rem / 16);

          >span {
            border-bottom: 0.0625rem solid #b0b4bd;
            padding-bottom: calc(16rem / 16);
          }
        }
      }
    }

    >.top {
      position: absolute;
      top: 0;
      right: 0;
      width: calc(100rem / 16);
    }

    >.bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(70rem / 16);
    }

    >.middle {
      position: absolute;
      left: 0;
      top: calc(400rem / 16);
      width: calc(10rem / 16);
    }

    >.logo {
      position: absolute;
      min-width: calc(100rem / 16);
      height: calc(100rem / 16);
      left: 50%;
      top: calc(40rem / 16);
      transform: translate(-50%, 0);
    }

    >.img-cont {
      width: 90%;
      height: calc(50rem / 16);
      display: flex;
      margin: auto;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);

      >img {
        width: calc(87rem / 16);
        height: calc(28rem / 16);
      }
    }
  }

  .checkbox {
    font-size: calc(12rem / 16);
  }

  /deep/ .van-checkbox__label {
    color: #fff !important;
  }
}
</style>
